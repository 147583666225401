import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { getUserData } from '@/auth/utils'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refIscrittiListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'codice', value: 'code', sortable: true },
    { key: 'stato', value: 'active', sortable: true },
    { key: 'nome', value: 'name', sortable: false },
    // { key: 'cognome', value: 'last_name', sortable: false },
    { key: 'ruolo', value: 'type', sortable: true },
    { key: 'email', value: 'email', sortable: true },
    { key: 'primo_login', value: 'first_login', sortable: true },
    { key: 'ultimo_login', value: 'last_login', sortable: true },
    { key: 'abilitato', value: 'disabled', sortable: true },
    { key: 'azioni' },
  ]

  const typeOptions = [
    { label: 'Incaricato', value: 'incaricato' },
    { label: 'Cliente', value: 'cliente' },
  ]

  const statusOptions = [
    { label: 'Attivo', value: 1 },
    { label: 'Inattivo', value: 0 },
  ]

  const genderOptions = [
    { label: 'M', value: 'm' },
    { label: 'F', value: 'f' },
  ]

  const siNoOptions = [
    { label: 'Si', value: 1 },
    { label: 'No', value: 0 },
  ]

  const perPage = ref(25)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const tree = ref(false)
  const isSortDirDesc = ref(true)

  const loggedUser = ref(null)

  const dataMeta = computed(() => {
    const localUsersCount = refIscrittiListTable.value ? refIscrittiListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localUsersCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localUsersCount,
      of: totalUsers.value,
    }
  })

  const fetchIscritti = (ctx, callback) => {
    let sorterResolved = sortBy.value
    if (sorterResolved !== 'id' && tableColumns.find(key => key.key === sorterResolved).value !== sorterResolved) {
      sorterResolved = tableColumns.find(key => key.key === sorterResolved).value
    }
    store
      .dispatch('users/fetchIscritti', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sorterResolved,
        sortDesc: isSortDirDesc.value,
        tree: tree.value,
      })
      .then(response => {
        if (response.data.data.users !== undefined) {
          const { users, meta } = response.data.data
          if (callback) {
            callback(users)
          }
          totalUsers.value = meta.total
        } else {
          const { meta } = response.data
          totalUsers.value = meta.total
        }
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento utenti',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchTreeIscritti = () => {
    store
      .dispatch('users/fetchTreeIscritti', {
        tree: tree.value,
      })
      .then(response => {
        const { meta } = response.data
        totalUsers.value = meta.total
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento iscritti',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    if (refIscrittiListTable.value) {
      refIscrittiListTable.value.refresh()
    } else if (tree.value) {
      fetchTreeIscritti()
    } else {
      fetchIscritti()
    }
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const refetchIscritto = () => {
    store.dispatch('users/fetchIscritto', { id: router.currentRoute.params.id })
      .then(() => {
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento utenti',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchLoggedUser = () => {
    store.dispatch('users/fetchIscritto', getUserData().id)
      .then(response => {
        loggedUser.value = response.data.data
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento utenti',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getListIscritti = () => store.getters['users/getListIscritti']
  const getTreeListIscritti = () => store.getters['users/getTreeListIscritti']
  const getDraftIscritto = () => store.getters['users/getDraftIscritto']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {

    loggedUser,

    // Options
    typeOptions,
    statusOptions,
    genderOptions,
    siNoOptions,
    //
    refetchLoggedUser,
    fetchIscritti,
    fetchTreeIscritti,
    refetchIscritto,
    getListIscritti,
    getTreeListIscritti,
    getDraftIscritto,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tree,
    refIscrittiListTable,
    refetchData,

    // Extra Filters
    //

  }
}
